html {
    font-size: 0.8rem;
 }
 h1 {
   font-size: 1 rem;
 }
 h2 {
   font-size: 0.9 rem;
 }
 
 @media (min-width: 768px) {
   html {
     font-size: 1.1rem;
   }
   h1 {
       font-size: 1.3rem;
   }
   h2 {
       font-size: 1.2rem;
   }
 }
 
 @media (min-width: 991px) {
     html {
     font-size: 1.2rem;
   }
   h1 {
       font-size: 1.5rem;
   }
   h2 {
       font-size: 1.4rem;
   }
 }
 
 @media (min-width: 1200px) {
   html {
     font-size: 1.2rem;
   }
   h1 {
       font-size: 1.7rem;
   }
   h2 {
       font-size: 1.6rem;
   }
 }
 