body, html {
  height: 100%;
  margin: 0;
  display:inline;
}

body {
  /*margin-top: 0;*/
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

.main{
  background-color: #b8c0b1;
  height: 100%; 
/*  margin: 0;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*.img img{width:100%;}*/


.bgdiv {
  /* The image used */
  /*background-image: url(../static/login-bg.jpg);*/
  background-size: cover;
  background-image: url("https://ham.qrits.com.ar/static/backham.jpg");
  /* Full height */
  height: 100%; 
}

.card-body>.card{margin-top:0.7em;}

th>img {margin: 0.4em;}

.card-header.match{background-color: #77bb85;}


.landing{
  margin:0 !important;
  padding: 0 !important;
}

.navbar .container{
  text-align: center!important;
  display:table!important;
  
}


/*implemtnacion imagenes escaladas*/

.caja{
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background: #333944;
}
.box{
  width: 100%;
  height: 100%;
  background: #CCC;
  overflow: hidden;
}

.box img{
  width: 100%;
  height: auto;
}
@supports(object-fit: cover){
    .box img{
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}

.card.quique {
  max-width: 12em;
  display:inline-block;
  margin-bottom:0.5em;
  margin-left:0.5em;
  
}
.card.quique.bg-base-card{
  background-color: #e7e1beef;
}

.card.greenLight{
  background-color: #61ab7c;
}

.card-body.bg-noColor{
  background-color: #b8c0b1 !important;
  
}

.btn-primary{
  background-color: #439139ef !important;
}


.navbar-custom{
    height:2em;
    display : flex;
    justify-content: center!important;
  
}

@media (min-width: 220px) and (max-width: 340px) {
  .text-size-responsive{
     font-size: 2em!important;
  }
}

.bandPlanTable thead td{
  border: 2px solid black;
  text-align: center;
  font-weight: bold;
  background-color:#6da069ef!important;
}

.bandPlan{
  border: 2px solid #6da069ef!important;

}
#map{
  position:fixed;
  width: 100%;
  height: 100%;
}